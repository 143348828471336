import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const SunriseUrologyLandingPage = () => (
  <PhysicianLandingPage
    physician="John Lin MD"
    institution="Sunrise Urology"
    referralCode="SUNRISEUROLOGY"
    physicianURL="https://sunriseurology.com/"
  />
)

export default SunriseUrologyLandingPage
